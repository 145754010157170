import './App.css';

import AppContextProvider from "./Contexts/AppContext";
import Application from "./Components/Application";

function App() {
    return (
        <AppContextProvider>
            <Application />
        </AppContextProvider>
    );
}

export default App;
