import {createContext, useEffect, useState} from "react";
import {Loading} from "../../Components/Loading/Loading";

export const ConfigurationContext = createContext({});

export const ConfigurationContextProvider = ({children}) => {
    const [configuration, setConfiguration] = useState(undefined);

    useEffect(() => {
        fetch('/configuration.json')
            .then(r => r.json())
            .then(c => setConfiguration(() => c));
    }, []);

    return configuration ? (
        <ConfigurationContext.Provider value={configuration}>
            {children}
        </ConfigurationContext.Provider>
    ) : <Loading />;
};