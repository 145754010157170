import './Footer.css';

const Footer = () => {
    return (
        <div className='Footer'>
            <div>
                contentED is a <a href='https://simplecat.software'>SimpleCat</a> product
            </div>
            <div>

            </div>
        </div>
    )
};

export default Footer;
