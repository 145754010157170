import {ConfigurationContextProvider} from "./Configuration";
import {AuthenticationContextProvider} from "./Authentication";
import {AnalyticsContextProvider} from "./Analytics";

function AppContextProvider({children}) {
    return (
        <ConfigurationContextProvider>
            <AuthenticationContextProvider>
                <AnalyticsContextProvider>
                    {children}
                </AnalyticsContextProvider>
            </AuthenticationContextProvider>
        </ConfigurationContextProvider>
    );
}

export default AppContextProvider;
