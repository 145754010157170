export const logWrapper = (callable, scope, logs) => {
    function logOutput() {
        const input = {callable, scope, logs};

        if (typeof input.callable !== 'function') {
            input.logs = input.scope;
            input.scope = input.callable;
            input.callable = undefined;
        }

        if (process.env.NODE_ENV === 'production') {
            console.groupCollapsed(input.scope);
        } else {
            console.group(input.scope);
        }

        for (const item of input.logs) {
            for (const [func, path] of Object.entries(item)) {
                let current = arguments;
                for (const segment of path.split('.')) {
                    current = current[segment];
                }
                console[func](current)
            }
        }

        console.groupEnd();

        return typeof input.callable === 'function' ?
            input.callable(...arguments) : undefined;
    }

    return logOutput;
}