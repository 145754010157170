import './ComingSoon.css';
import Logo from "../Graphics/Logo";

export const ComingSoon = () => {
  return (
      <div className='ComingSoon'>
          <Logo className='ComingSoonLogo' />
          <div className='ComingSoonText'>
              <p>Coming Soon!</p>
              <p>
                  A <a  href='https://simplecat.software'>Simple Cat</a> product.
              </p>
          </div>
      </div>
  )
};
