import {WithMargin} from "../Components/Content";

const HomePage = () => {
    return (
        <WithMargin>
            <h1>contentED <small>easy content editing and hosting</small></h1>
            <p></p>
        </WithMargin>
    )
};

export default {
    path: '/',
    Component: HomePage,
};
