import {CenterStage} from "../Components/Content";
import {logWrapper} from "../Helpers";
import {Link} from "react-router-dom";
import './Login.css'

const LoginPage = () => {
    const formProperties = {
        onSubmit(event) {
            event.preventDefault();
            const email = event.target.querySelector('input[name="email"]').value;
            const password = event.target.querySelector('input[name="password"]').value;

            logWrapper(({email, password}) => {
                throw new Error("Cannot log in...")
            }, 'Login Submit Event', [{table: '0'}])({email, password});
        }
    };

    return (
        <CenterStage>
            <form className='Login' {...formProperties}>
                <fieldset>
                    <legend>Login</legend>
                    <label>
                        Email
                        <input type='email' name='email' placeholder='email@address.com' required />
                    </label>
                    <label>
                        Password
                        <input type='password' name='password' placeholder='password' required />
                    </label>
                    <button>Login</button>
                    <Link to='/register'>Register</Link>
                </fieldset>
            </form>
        </CenterStage>
    )
};

export default {
    path: '/login',
    Component: LoginPage,
};
