import {createContext, useContext, useEffect, useState} from "react";
import Auth from '@aws-amplify/auth';
import {ConfigurationContext} from "./Configuration";
import {Loading} from "../../Components/Loading/Loading";

export const AuthenticationContext = createContext(undefined);

export const AuthenticationContextProvider = ({children}) => {
    const configuration = useContext(ConfigurationContext);
    const [auth, setAuth] = useState(undefined);

    useEffect(() => {
        if (configuration?.identityPoolId) {
            setAuth(() => {
                Auth.configure({
                    Auth: {
                        identityPoolId: configuration.identityPoolId,
                        region: 'eu-west-2',
                    },
                });
                return Auth;
            });
        }
    }, [configuration?.identityPoolId]);

    return auth ? (
        <AuthenticationContext.Provider value={auth}>
            {children}
        </AuthenticationContext.Provider>
    ) : <Loading />;
}
