import './CenterStage.css';

const CenterStage = ({children}) => (
    <div className='ContentCenterStage'>
        <div>
            {children}
        </div>
    </div>
);

export default CenterStage;
