import Home from './Home';
import Login from "./Login";
import Header from "../Components/Application/Header";
import Footer from "../Components/Application/Footer";
import {useContext, useEffect} from "react";
import {AnalyticsContext} from "../Contexts/AppContext/Analytics";
import {useLocation} from "react-router-dom";
import {RouteError} from "../Components/Application/RouteError";

const pages = [Home, Login];


export default pages.map(p => ({
    ...p,
    errorElement: <RouteError />,
    Component: () => {
        const analytics = useContext(AnalyticsContext);
        const location = useLocation();

        useEffect(() => {
            analytics.record({
                name: 'page:visit',
                attributes: {
                    url: window.location.href,
                    origin: window.location.origin,
                    path: window.location.pathname,
                },
            })
        }, [location]);

        return (
            <>
                <Header/>
                <p.Component/>
                <Footer/>
            </>
        );
    },
}));
