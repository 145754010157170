import {createContext, useContext, useEffect, useState} from "react";
import {Analytics} from '@aws-amplify/analytics';
import {ConfigurationContext} from "./Configuration";
import {AuthenticationContext} from "./Authentication";
import {Loading} from "../../Components/Loading/Loading";
import {eventWrapper} from "@testing-library/user-event/dist/utils";
import {logWrapper} from "../../Helpers";

export const AnalyticsContext = createContext(undefined);

export const AnalyticsContextProvider = ({children}) => {
    const configuration = useContext(ConfigurationContext);
    const authentication = useContext(AuthenticationContext);
    const [analytics, setAnalytics] = useState(undefined);

    useEffect(() => {
        if (authentication && configuration?.analyticsAppId) {
            setAnalytics(() => {
                if (process.env.NODE_ENV !== 'production') {
                    return {
                        record: logWrapper('Analytics Event', [
                            {log: '0.name'},
                            {table: '0.attributes'},
                        ]),
                    }
                }
                Analytics.configure({
                    AWSPinpoint: {
                        appId: configuration.analyticsAppId,
                        region: 'eu-west-2',
                        mandatorySignIn: false,
                    },
                });
                return Analytics;
            });
        }
    }, [authentication, configuration?.analyticsAppId]);

    return analytics ? (
        <AnalyticsContext.Provider value={analytics}>
            {children}
        </AnalyticsContext.Provider>
    ) : <Loading/>;
}
