import './Loading.css';
import LoadingGraphic from './Loading.svg';
import Logo from "../Graphics/Logo";

export const Loading = () => {
  return (
      <div className='Loading'>
          <Logo className='LoadingLogo' />
          <img src={LoadingGraphic} className='LoadingIndicator' alt="Loading graphic"/>
          <div className='LoadingText'>LOADING</div>
      </div>
  )
};
