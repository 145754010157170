import React, {useContext} from "react";
import {AnalyticsContext} from "../../Contexts/AppContext/Analytics";
import {useRouteError} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {CenterStage} from "../Content";

export const RouteError = () => {
    const analytics = useContext(AnalyticsContext);
    const error = useRouteError();

    analytics.record({
        name: 'error',
        attributes: {
            status: error.status,
            message: error.statusText,
        }
    });
    return (
        <>
            <Header/>
            <CenterStage>
                <h1>Sorry, something went wrong</h1>
                <p>You've hit an error and we're really sorry.</p>
                <p>Your data should be saved, try navigating to another page.</p>
            </CenterStage>
            <Footer/>
        </>
    );
}
