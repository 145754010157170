import Pages from "../../Pages";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {RouteError} from "./RouteError";
import HoldingPage from "./HoldingPage";

const showHoldingPage = true; // process.env.NODE_ENV === 'production';
const router = createBrowserRouter(Pages);

const Application = () =>
    showHoldingPage ?
        <HoldingPage /> :
        <RouterProvider router={router}/>;

export default Application;
