import {NavLink} from "react-router-dom";
import Logo from "../Graphics/Logo";
import './Header.css'
import {useContext} from "react";
import {AuthenticationContext} from "../../Contexts/AppContext/Authentication";

const Header = () => {
    const authentication = useContext(AuthenticationContext);

    return (
        <div className='Header'>
            <nav>
                <NavLink to='/' title='Home'>
                    <Logo className='HeaderLogo'/>
                </NavLink>
                <ul>
                    <li>
                        <NavLink to='/about' title='Login'>
                            About
                        </NavLink>
                    </li>
                    <li>
                        {authentication.user ? (
                            <NavLink to='/profile' title='My Profile'>
                                My Profile
                            </NavLink>
                        ) : (
                            <NavLink to='/login' title='Login'>
                                Login
                            </NavLink>
                        )}
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Header;
